<template lang="pug">
UniversalLink(v-if='bannerData && !isSimpleMode' tracking-event-type='sitewideBanner' :link='url')
  div(class='flex justify-center m-auto h-8' :class='backgroundColorClass' :style='backgroundColorStyle')
    div(class='h-full w-auto')
      PictureWrapper(v-bind='pictureBindings' :alt='bannerData.alt' class='max-h-8')
</template>

<script setup lang="ts">
const { $sitewideConfig, $device, $storyblok } = useNuxtApp()
const rootStore = useRootStore()

const bannerData = computed(() => {
  return $sitewideConfig.sitewideBanner?.content
})

const isSimpleMode = computed(() => {
  if ($storyblok.isEditing.value) return false
  return rootStore.$state.isSimpleMode
})

// Background Class Options
const backgroundColorClass = computed(() => {
  // Early out if its already applied via Style tag
  if (backgroundColorStyle?.value?.backgroundColor) return

  if ($device.value.isMobile) {
    return generateBackgroundColorClass(bannerData.value.mobileBackgroundColor)
  }

  return generateBackgroundColorClass(bannerData.value.desktopBackgroundColor)
})

// Custom Background Colors
const backgroundColorStyle = computed(() => {
  if (!bannerData.value.mobileCustomBackgroundColor && !bannerData.value.desktopCustomBackgroundColor) return

  if ($device.value.isMobile) return { backgroundColor: bannerData.value.mobileCustomBackgroundColor }

  return { backgroundColor: bannerData.value.desktopCustomBackgroundColor }
})

const url = computed(() => {
  if ($device.value.isMobile) return bannerData.value.mobileUrl

  return bannerData.value.desktopUrl
})

const pictureBindings = computed(() => {
  let desktopImage = null
  let mobileImage = null

  if (bannerData.value.desktopImage.filename.includes('.svg')) {
    mobileImage = $storyblok.getNormalizedAsset(
      {
        src: bannerData.value.mobileImage.filename,
        width: 768,
        height: 32,
      },
      768,
      {
        width: 768,
        height: 32,
      }
    )

    desktopImage = $storyblok.getNormalizedAsset(
      {
        src: bannerData.value.desktopImage.filename,
        width: 1334,
        height: 32,
      },
      1334,
      {
        width: 1334,
        height: 32,
      }
    )
  } else {
    mobileImage = $storyblok.getNormalizedAsset(bannerData.value.mobileImage.filename, 768, {
      width: 768,
      height: 32,
    })
    desktopImage = $storyblok.getNormalizedAsset(bannerData.value.desktopImage.filename, 1334, {
      width: 1334,
      height: 32,
    })
  }

  return {
    mobileSrc: mobileImage.src,
    mobileWidth: mobileImage.width,
    mobileHeight: mobileImage.height,

    desktopSrc: desktopImage.src,
    desktopWidth: desktopImage.width,
    desktopHeight: desktopImage.height,
  }
})
</script>
