
import { updateAppConfig } from '#app/config'
import { defuFn } from 'defu'

const inlineConfig = {
  "buildId": "60d96f73903ec465bb3a712cd0fd3e30a463b798",
  "nuxt": {
    "buildId": "ee114cbf-d1f2-4b8f-99f9-5f30c9a85cae"
  }
}

// Vite - webpack is handled directly in #app/config
if (import.meta.hot) {
  import.meta.hot.accept((newModule) => {
    updateAppConfig(newModule.default)
  })
}



export default /*@__PURE__*/ defuFn(inlineConfig)
