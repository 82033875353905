<template lang="pug">
div
  //- Tool bar for the storyblok editor
  div(
    v-if='$storyblok.isEditing.value && isLinkbarStory'
    class='border-b mt-4 mb-4 space-x-2 uppercase font-bold relative z-[61]'
  )
    div(
      class='inline-block py-2 px-4 border border-b-0 rounded-t cursor-pointer'
      :class='{ "text-white bg-info": !isSimple, "bg-white": isSimple }'
      @click='setIsSimple(false)'
    ) Standard

    div(
      class='inline-block py-2 px-4 border border-b-0 rounded-t cursor-pointer'
      :class='{ "text-white bg-info": isSimple, "bg-white": !isSimple }'
      @click='setIsSimple(true)'
    ) Simple

  template(v-if='linkBarData')
    CmsStoryblok(v-if='isSimpleMode' :sections='linkBarData?.simpleLinkbar')
    CmsStoryblok(v-else :sections='linkBarData?.standardLinkbar')
</template>

<script setup lang="ts">
const { $sitewideConfig, $storyblok } = useNuxtApp()
const rootStore = useRootStore()

const { data: previewData } = await useAsyncData('linkbar-data', async () => {
  if (!$storyblok.isPreview.value || $storyblok.contentType.value !== 'link-bar') return null

  const preview = await $storyblok.getPreviewStory<LinkBarStory>()
  return preview?.story.content ?? null
})

const isSimple = ref(false)

const isSimpleMode = computed(() => {
  if ($storyblok.isEditing.value) return isSimple.value
  return rootStore.$state.isSimpleMode
})

const linkBarData = computed(() => {
  return previewData.value || $sitewideConfig.linkBar
})

const isLinkbarStory = computed(() => {
  return $storyblok.contentType.value === 'link-bar'
})

const setIsSimple = (bool: boolean) => {
  isSimple.value = bool
}
</script>
