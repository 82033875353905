export default defineNuxtPlugin(async () => {
  const config = useRuntimeConfig()
  const mode = config.public.mode == 'staging' ? 'staging' : 'prod'
  const domain = useState('sitewide-domain', () => '')
  const query = useRoute().query
  const timeTravel = query.timeTravel ? `?timeTravel=${query.timeTravel}` : ''

  // This is a plugin and spins up seemingly before vitest (via nuxt-vitest) and so we don't have a chance to setup a mock for this.  Use a mocker instead.  See useOrderControls.nuxt.spec.ts
  // TODOLATER: When we use this again it should be import.meta.test
  // if (process.env.VITEST) return {}

  if (import.meta.server) {
    const host = useRequestHeader('host')!.replace(/:\d+/, '')

    domain.value = host === 'localhost' ? 'staging.realtruck.com' : host
  }

  // yes client-side, domain will be empty, that's why we need key
  // TODOLATER: Something we can ask Alex (Nuxt Team) about (default auto-retry)
  const { data, error } = await useFetch<SitewideResponse>(
    `https://thiecommerce.com/${mode}/frontend/domains/${domain.value}/sitewide/${timeTravel}`,
    { key: 'sitewidefetch' }
  )

  // TODOLATER: Change the FE service to throw an error instead of an empty object
  // if Storyblok fails. Update this error check when we do.

  // Sitewide will always return an object, but at the very least the config should exist
  if (error.value || !data.value?.config) throw new Error(`Could not get Sitewide data for domain (${domain.value})!`)

  const navbar = shallowRef(data.value.navbar)

  onNuxtReady(async () => {
    const log = useLogger()

    const navbarData = await $fetch<SitewideResponse['navbar']>(
      `https://thiecommerce.com/${mode}/frontend/domains/${domain.value}/sitewide/navbar`
    )

    if (!navbarData) {
      log.error('NAVBAR_ERROR: fetching navbar', { error })
      return
    }

    navbar.value = navbarData
  })

  // TODOLATER: When cli is about done, refresh these types!
  return {
    provide: {
      sitewideConfig: {
        config: data.value.config.content,
        header: data.value.header?.content,
        sitewideBanner: data.value.sitewideBanner,
        footer: data.value.footer?.content,
        navbar, // can't use .value or reactivity breaks
        seo: data.value.seo,
        styles: data.value.styles,
        channelId: data.value.channelId,
        domain: domain.value,
        sitePrefix: data.value.sitePrefix,
        linkBar: data.value.linkBar?.content,
      },
    },
  }
})

interface SitewideResponse {
  config: { content: GeneralConfig & IntegrationsConfig & FeaturesConfig }
  header?: { content: Header }
  sitewideBanner?: { content: Banner }
  navbar?: { content: Navbar }
  footer?: { content: Footer }
  seo?: { content: Seo }
  styles?: { content: Styles }
  channelId: string
  domain: string
  sitePrefix: string
  linkBar?: { content: LinkBar }
}

interface Link {
  cached_url: string
}

declare global {
  // Transforms IntegrationsConfig into a list of types for all keys that end in Enabled.
  type Integration = keyof {
    [key in keyof IntegrationsConfig as key extends `${infer K}Enabled` ? K : never]: IntegrationsConfig[key]
  }

  interface LinkBar {
    component: 'link-bar'
    standardLinkbar: Widget[]
    simpleLinkbar: Widget[]
  }

  interface Header {
    component: 'header'
    headerSimple: Widget[]
    headerStandard: Widget[]
    stickyEnabled: boolean
  }

  interface Footer {
    component: 'footer'
    footerSimple: Widget[]
    footerStandard: Widget[]
  }

  // link-menu, tree-menu, text-menu-item, cms-menu
  interface Navbar {
    component: 'navbar'
    items: LinkMenu[] | TextMenuItem[] | CmsMenu[] | TreeMenu[]
    desktopTextColor: string
    fitmentTextColor: string
    desktopHoverColor: string
    desktopHoverStyle: string
    desktopHoverTextColor: string
    desktopBackgroundColor: string
    fitmentBackgroundColor: string
    desktopBottomBorderColor: string
    hideOnSticky: boolean
  }
  interface TreeMenu extends Widget {
    text: string
    noSideMenu: boolean
    Visibility: StringBoolean
    items: TreeMenuItem[]
  }

  interface TreeMenuItem extends Widget {
    text: string
    link: StoryblokLink
    items: TreeMenuItem[]
  }
  interface CmsMenu extends Widget {
    text: string
    sections: object
  }
  interface LinkMenu extends Widget {
    text: string
    items: TextMenuItem[]
  }

  interface TextMenuItem extends Widget {
    text: string
    link: StoryblokLink
  }
}

interface GeneralConfig {
  phoneGeneral: string
  privacyUrl?: Link
  returnsUrl?: Link
  madeByRealTruckBrands: { data: string[] }
  madeByRealTruckOnlineOnlyBrands: { data: string[] }
  madeByRealTruckEnabled: boolean
  sameDayShippingMode: 'RAPIDSHIP' | 'QUICKSHIP'
  sameDayShippingEnabled: boolean
  siteName: string
  noIndexWholeSite: boolean
}

interface IntegrationsConfig {
  accessibeEnabled: boolean
  affirmApiKey: string
  affirmEnabled: boolean
  affirmShowOnCatalogPages: boolean
  algoliaApiKey: string
  algoliaAppId: string
  algoliaEnabled: boolean
  amplitudeEnabled: string
  amplitudeKey: string
  boltEnabled: boolean
  boltPublicKey: string
  brightedgeAccountId: string
  brightedgeEnabled: boolean
  bugsnagApiKey: string
  bugsnagEnabled: boolean
  cognitoClientId: string
  cognitoEnabled: boolean
  cognitoUserPoolId: string
  constructorEnabled: boolean
  constructorTestEnabled: boolean
  constructorIndexKey: string
  ellaEnabled: boolean
  ellaKey: string
  extendEnabled: boolean
  extendShippingProtectionEnabled: boolean
  forterEnabled: boolean
  forterId: string
  geolocationEnabled: boolean
  googlePlacesEnabled: boolean
  guaranteedFitEnabled: boolean
  installernetEnabled: boolean
  installernetPageUrl: string
  invocaEnabled: boolean
  invocaNetworkId: string
  invocaTagId: boolean
  livepersonEnabled: boolean
  livepersonEngagementId: string
  livepersonSiteId: string
  paypalEnabled: boolean
  paypalClientId: string
  segmentApiKey: boolean
  segmentEnabled: boolean
  speedcurveEnabled: boolean
  speedcurveId: string
  speedcurveLogFirstHit: boolean
  storyblokApiKey: string
  storyblokEnabled: boolean
  stripeEnabled: boolean
  stripePaymentRequestButton: boolean
  wisepopsApiKey: string
  wisepopsEnabled: boolean
  wisepopsSiteId: string
  yotpoApiKey: string
  yotpoEnabled: boolean
  yotpoProductGalleryId: string
}
interface FeaturesConfig {
  orderReturnsEnabled: boolean
  orderCancellationsEnabled: boolean
  extraButtonHeading: string
  extraButtonText: string
  extraButtonLink: Link
  brandsEnabled: boolean
  trustIcon: string
  trustText: string
  buildsEnabled: boolean
  errorPageImage: StoryblokAsset
  useSecondLevel: boolean
  brandsBannerFile: StoryblokAsset
  geolocationEnabled: boolean
  configureTechApiKey: string
  enableGiftCardInput: boolean
  dealerLocatorEnabled: boolean
  installGuideSearchEnabled: boolean
  dealerLocatorOnlineEnabled: boolean
  recommendedProductsEnabled: boolean
  dealerLocatorShowBrandFacet: boolean
  dealerLocatorShowProductPageLink: boolean
  dealerLocatorInternationalEnabled: boolean
  salesRepsSearchEnabled: boolean
  shareCartEnabled: boolean
  backInStockNotificationEnabled: boolean
  newsletterEnabled: boolean
  newsletterSmsEnabled: boolean
  newsletterSmsText: string
  newsletterOffersText: string
  nonTransactionalEnabled: boolean
  rightAlignedSplitterEnabled: boolean
}

interface Banner {
  mobileUrl: string
  mobileImage: StoryblokAsset
  mobileBackgroundColor: string
  mobileCustomBackgroundColor: string

  desktopUrl: string
  desktopImage: StoryblokAsset
  desktopBackgroundColor: string
  desktopCustomBackgroundColor: string
}

interface Seo {
  brandTitle: string
  searchTitle: string
  titleSuffix: string
  productTitle: string
  categoryTitle: string
  specialsTitle: string
  mmyDescription: string
  mmyTitleFitment: string
  brandDescription: string
  newProductsTitle: string
  productLineTitle: string
  brandTitleFitment: string
  searchDescription: string
  mmyDescriptionMake: string
  mmyDescriptionYear: string
  productDescription: string
  categoryCustomTitle: string
  categoryDescription: string
  mmyDescriptionModel: string
  specialsDescription: string
  brandDescriptionMake: string
  brandDescriptionYear: string
  categoryTitleFitment: string
  specialsTitleFitment: string
  brandDescriptionModel: string
  newProductsDescription: string
  productLineDescription: string
  categoryDescriptionMake: string
  categoryDescriptionYear: string
  newProductsTitleFitment: string
  productLineTitleFitment: string
  specialsDescriptionMake: string
  specialsDescriptionYear: string
  categoryDescriptionModel: string
  specialsDescriptionModel: string
  categoryCustomDescription: string
  categoryCustomTitleFitment: string
  newProductsDescriptionMake: string
  newProductsDescriptionYear: string
  productLineDescriptionMake: string
  productLineDescriptionYear: string
  newProductsDescriptionModel: string
  productLineDescriptionModel: string
  categoryCustomDescriptionMake: string
  categoryCustomDescriptionYear: string
  categoryCustomDescriptionModel: string
}

interface Styles {
  css: string
  icon: StoryblokAsset
  colorDark: StylesColor
  colorGray: StylesColor
  colorInfo: StylesColor
  noResults: StoryblokAsset
  colorBlack: StylesColor
  colorLight: StylesColor
  colorWhite: StylesColor
  fontSizeBase: string
  fontSizeLg: string
  fontSizeSm: string
  fontSizeXl: string
  fontSizeXs: string
  fontSize2xl: string
  fontSize3xl: string
  fontSize4xl: string
  colorAction: StylesColor
  colorDanger: StylesColor
  colorPrimary: StylesColor
  colorSuccess: StylesColor
  colorWarning: StylesColor
  fontFileBold: StoryblokAsset
  fontFileNormal: StoryblokAsset
  fontFileExtraBold: StoryblokAsset
  colorGrayDark: StylesColor
  colorInfoDark: StylesColor
  colorGrayLight: StylesColor
  colorInfoLight: StylesColor
  colorSecondary: StylesColor
  colorActionDark: StylesColor
  colorDangerDark: StylesColor
  colorGrayDarker: StylesColor
  colorActionLight: StylesColor
  colorDangerLight: StylesColor
  colorGrayDarkest: StylesColor
  colorGrayLighter: StylesColor
  colorPrimaryDark: StylesColor
  colorSuccessDark: StylesColor
  colorWarningDark: StylesColor
  colorGrayLightest: StylesColor
  colorPrimaryLight: StylesColor
  colorSuccessLight: StylesColor
  colorWarningLight: StylesColor
  colorSecondaryDark: StylesColor
  colorSecondaryLight: StylesColor
}

interface StylesColor {
  color: string
}
