export default defineNuxtPlugin(() => {
  const { $sitewideConfig, $uiEvents } = useNuxtApp()
  const log = useLogger('EXTEND')
  const script = runScriptOnDemand()
  const notifications = useNotifications()
  const { isProduction } = useUtils()
  const { getApiUrl } = useUrls()
  const router = useRouter()
  const rootStore = useRootStore()
  const cartStore = useCartStore()

  const cartUrl = getApiUrl('cart')

  const config = isProduction()
    ? {
        storeId: 'f8920f29-51d9-4c84-930c-ac56c472106b',
        environment: 'production',
      }
    : {
        storeId: '9f7ededd-628e-47bf-b028-a83925bc4977',
        environment: 'demo',
      }

  script.addSrc('https://sdk.helloextend.com/extend-sdk-client/v1/extend-sdk-client.min.js')
  script.addCallback(() => {
    window.Extend.config(config)
  })

  function _load() {
    return new Promise<void>((resolve, reject) => {
      script.addCallback(() => {
        resolve()
      })

      script.addErrorCallback(() => {
        reject(new Error('Unable to load Extend'))
      })

      script.load()
    })
  }

  // Simple state to track if the modal is open
  const isModalOpen = ref(false)

  async function openModal(item: any) {
    // If modal is already open then we shouldn't do anything
    if (isModalOpen.value) return
    isModalOpen.value = true

    try {
      // Wait for the Extend script to load
      await _load()
      // Add guard so we can close the modal if the route changes
      const removeCloseGuard = router.beforeEach((to, from, next) => {
        window.Extend.modal.close()
        isModalOpen.value = false
        removeCloseGuard()
        next()
      })

      // We need to manually get the plans offered because extend doesn't tell us about
      // the offers they showed to the user in the modal.
      // We are starting this promise here so it can run in the background. We will only use its info
      // when the modal closes
      const plansRequest = getPlans(item)

      const extendModalPromise = new Promise<void>((resolve) => {
        window.Extend.modal.open({
          referenceId: item.sku,
          category: item.category,
          price: item.salePrice,
          // This function will run when the extend modal closes
          // when the user accept/decline protection, clicks off the modal, or extend has nothing to offer
          async onClose(plan: any, product: any) {
            // If this function runs then that means the modal closed so we can remove our
            // route guard which would close the modal if the user hit the back/forward button
            // and reset our open bool
            removeCloseGuard()
            isModalOpen.value = false

            // Make sure the plans that we requested finished
            const plans = await plansRequest

            // If we have plans then we need to make sure we report that
            if (plans) {
              $uiEvents.$emit('warrantyOfferMade', {
                ...item,
                warranty: plans,
              })
            }

            // When both of these exist then the user selected a plan and we need to add it to the cart
            // If they do not exist then extend didn't have any plans to offer the user
            if (plan && product) {
              try {
                // For some reason, extend formats this plan obj differently than the getOffers endpoint.
                // So we need to make sure an id prop exists in the plan so the whole system can use the same id prop
                const formattedPlan = { id: plan.planId, ...plan }
                await addWarranty(item, formattedPlan)
              } catch (error) {
                notifications.addError('Error', 'Unable to add warranty')
                log.error('onClose', { error })
              }
            } else if (plans) {
              // If we make it here then the user either declined protection/closed the modal
              // But if extend did have plans for the item then we need to trigger the decline event
              $uiEvents.$emit('warrantyDeclined', {
                ...item,
                warranty: plans,
              })
            }

            resolve()
          },
        })
      })

      // This will wait for the extend action to complete before we resolve this function
      await extendModalPromise
    } catch (error) {
      log.error('openModal', { error })
    }
  }

  async function addWarranty(cartItem: any, plan: any) {
    // If we don't have a cartItem or plan then don't do this
    if (!cartItem || !plan) return

    try {
      await $fetch(`${cartUrl}${rootStore.permId}/items/${cartItem.itemId}/warranty`, {
        method: 'POST',
        body: {
          plan: plan.id,
          qty: cartItem.qty,
        },
        headers: {
          'x-site': $sitewideConfig.sitePrefix,
        },
      })

      $uiEvents.$emit('addToCart', {
        productName: 'EXTEND-WARRANTY',
        sku: plan.id,
        qty: cartItem.qty,
        salePrice: plan.price,
      })
      $uiEvents.$emit('addWarranty', {
        ...cartItem,
        warranty: plan,
      })

      await cartStore.getCart()
    } catch (error) {
      log.error('addWarranty', { error })
    }
  }

  async function removeWarranty(cartItem: any) {
    // If we don't have a warranty then we shouldn't do this
    if (!cartItem.warranty) return

    try {
      await $fetch(`${cartUrl}${rootStore.permId}/items/${cartItem.itemId}/warranty`, {
        method: 'DELETE',
        headers: {
          'x-site': $sitewideConfig.sitePrefix,
        },
      })

      $uiEvents.$emit('removeFromCart', {
        productName: 'EXTEND-WARRANTY',
        sku: cartItem.warranty.id,
        qty: cartItem.qty,
        salePrice: cartItem.warranty.price,
      })
      $uiEvents.$emit('removeWarranty', {
        ...cartItem,
        // Just doing this to make sure that we don't carry any reactivity into this on accident
        warranty: { ...cartItem.warranty },
      })

      await cartStore.getCart()
    } catch (error) {
      log.error('removeWarranty', { error })
    }
  }

  async function getPlans(item: any) {
    let plans

    try {
      // Wait for the Extend script to load
      await _load()

      if (item) {
        const extendOffersResponse = await window.Extend.getOffer({
          referenceId: item.sku,
          category: item.category,
          price: item.salePrice,
        })

        // Example: Plans Payload
        // {
        //     "adh": [],
        //     "base": [],
        //     "roadhazard": [],
        //     "recommended": "base" // this is the value of the key offered: adh, base, roadhazard
        //   }

        // Check that the resp is in the format that we are expecting
        if (extendOffersResponse.plans?.recommended) {
          // Get the plans that extend offers for the sku
          const plansToOffer = extendOffersResponse.plans[extendOffersResponse.plans.recommended] || []

          // If we have plans then we need to set them to the return var
          if (plansToOffer.length > 0) plans = extendOffersResponse.plans[extendOffersResponse.plans.recommended]
        }
      }
    } catch (error) {
      log.error('getPlans', { error })
    }

    return plans
  }

  async function getShippingProtectionPrice(cartItems: CartItem[]): Promise<number> {
    let url = 'https://api-demo.helloextend.com/shipping-offers/quotes'
    if (isProduction()) url = url.replace('-demo', '')

    const items = getCartItemsExcludingShippingProtection(cartItems).map((item) => {
      return {
        referenceId: item.sku,
        quantity: item.qty,
        purchasePrice: item.itemPrice,
        productName: item.productLineName,
        category: item.category,
        shippable: true,
      }
    })

    const resp = await $fetch<ExtendShippingProtectionResponse>(url, {
      method: 'post',
      body: {
        storeId: config.storeId,
        currency: 'USD',
        items,
      },
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })

    return resp.premium
  }

  // filter our extend shipping warranty
  function getCartItemsExcludingShippingProtection(items: CartItem[] | []) {
    if (!items || !Array.isArray(items)) return items || []
    return items.filter((item) => item.skuSlug != 'extend-shipping-protection')
  }

  function getShippingProtectionCartItem(items: CartItem[]) {
    return items.find((item) => item.skuSlug == 'extend-shipping-protection')
  }

  return {
    provide: {
      extend: {
        openModal,
        getPlans,
        addWarranty,
        removeWarranty,
        getShippingProtectionPrice,
        getCartItemsExcludingShippingProtection,
        getShippingProtectionCartItem,
        load: _load,
      },
    },
  }
})

interface ExtendShippingProtectionResponse {
  currency: string
  id: string
  offerType: string
  premium: number
}
