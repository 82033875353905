export default class GeoProvider {
  state: Ref<GeoProviderState>
  private saveCallback: Function | null
  private _geocoder: any
  private isEnabled
  private isProduction
  private log
  private overlay

  // args will either be a string or nothing
  constructor() {
    const { $overlay } = useNuxtApp()
    const log = useLogger('GEO_PROVIDER')
    const { isIntegrationEnabled, isProduction } = useUtils()
    this.isEnabled = isIntegrationEnabled
    this.isProduction = isProduction()
    this.log = log
    this.overlay = $overlay

    // region is the state.value abbreviation
    // regionName is the state.value spelled out
    // locationOverride is a way for us to override the displayed location in the modal
    //   - used for the installernet cart line item display
    this.state = useState<GeoProviderState>('geoprovider', () => ({
      city: '',
      country: '',
      region: '',
      regionName: '',
      zipcode: '',
      latitude: '',
      longitude: '',
      locationOverride: null,
      edgePostalCode: '',
      browserPostalCode: '',
      userPostalCode: '',
      setFromCookie: false,
    }))

    this.saveCallback = null
  }

  get formattedLocation() {
    if (!this.state.value.city || !this.state.value.region) return 'Unknown location'

    return `${this.state.value.city}, ${this.state.value.region}`
  }

  get formattedLocationWithZip() {
    if (!this.state.value.city || !this.state.value.region || !this.state.value.zipcode) return 'Unknown location'

    return `${this.state.value.city}, ${this.state.value.region} ${this.state.value.zipcode}`
  }

  get overrideFormattedLocation() {
    if (!this.state.value.locationOverride) return null

    const { city, region, zipcode } = this.state.value.locationOverride
    if (!city || !region || !zipcode) return 'Unknown location'

    return `${city}, ${region} ${zipcode}`
  }

  get city() {
    return this.state.value.city
  }

  get country() {
    return this.state.value.country
  }

  get region() {
    return this.state.value.region
  }

  get regionName() {
    return this.state.value.regionName
  }

  get zipcode() {
    return this.state.value.zipcode
  }

  get latitude() {
    return this.state.value.latitude
  }

  get longitude() {
    return this.state.value.longitude
  }

  get edgePostalCode() {
    return this.state.value.edgePostalCode
  }

  get browserPostalCode() {
    return this.state.value.browserPostalCode
  }

  get userPostalCode() {
    return this.state.value.userPostalCode
  }

  openModal(options: any = {}) {
    if (options) {
      const { cb, city, region, zipcode } = options
      if (cb) this.saveCallback = cb
      if (city || region || zipcode)
        this.state.value.locationOverride = {
          city,
          region,
          zipcode,
        }
    }

    this.overlay.open('confirmLocationPopup')
  }

  closeModal() {
    this.state.value.locationOverride = null
    this.saveCallback = null
    this.overlay.close()
  }

  setGeoLocationByCookie(cookie: any) {
    try {
      this._setLocation(cookie)
      if (cookie.zipcode) this.state.value.userPostalCode = cookie.zipcode
      this.state.value.setFromCookie = true
    } catch (error) {
      console.log('Could not restore geoLocation, bad cookie format')
    }
  }

  setGeoLocationByHeaders(headers: any) {
    try {
      this._setLocation(headers)
      if (headers.edgePostalCode) this.state.value.edgePostalCode = headers.edgePostalCode
    } catch (error) {
      console.log('Could not set geoLocation by headers')
    }
  }

  async setGeoLocationByZip(zip: any) {
    if (import.meta.server) return
    // set the geo location from zip
    await this._setGeoLocation({
      address: '',
      componentRestrictions: { country: 'US', postalCode: zip },
    })
    this.state.value.userPostalCode = this.state.value.zipcode

    // Expire date 10 years from now
    const expireDate = new Date(new Date().setFullYear(new Date().getFullYear() + 10))
    // when set manually by user save location to cookie so we can set the location from the cookie on later visits
    const cookie = useCookie('geoLocationV2', {
      secure: this.isProduction,
      expires: expireDate,
      maxAge: 315360000, // 10 years
    })
    cookie.value = JSON.stringify({
      city: this.state.value.city,
      country: this.state.value.country,
      region: this.state.value.region,
      regionName: this.state.value.regionName,
      zipcode: this.state.value.zipcode,
      latitude: this.state.value.latitude,
      longitude: this.state.value.longitude,
    })
  }

  private _getGeocoder() {
    return new Promise<any>((resolve) => {
      // We can't do this on the server.
      if (import.meta.server) {
        resolve(null)
        return
      }

      // if we have an existing geocoder then return it.
      if (this._geocoder) {
        resolve(this._geocoder)
        return
      }

      // if googlePlaces isn't enabled
      if (!this.isEnabled('googlePlaces')) {
        resolve(null)
        return
      }

      // initialize the geocoder because other function inside of geoProvider require this
      this._geocoder = new window.google.maps.Geocoder()
      resolve(this._geocoder)
    })
  }

  // Function used for setting client side location updates
  private async _setGeoLocation(geoRequest: any) {
    const { $googlePlaces } = useNuxtApp()
    await $googlePlaces.load()

    const geocoder = await this._getGeocoder()

    if (!geocoder) return

    // get address info from lat/lng or zip
    const { results } = await geocoder.geocode(geoRequest)

    const [address] = results
    if (!address) {
      this.log.error('GEO_RESPONSE_EMPTY', {
        request: geoRequest,
        response: results,
      })
      throw new Error('NO_RESULTS')
    }

    const tempGeoData = address.address_components.reduce(
      (acc: any, part: any) => {
        part.types.forEach((type: any) => {
          switch (type) {
            case 'locality':
            case 'sublocality':
            case 'sublocality_level_1':
            case 'neighborhood':
            case 'administrative_area_level_3':
              acc.city = part.long_name
              break

            case 'administrative_area_level_1':
            case 'administrative_area_level_2':
              acc.region = part.short_name
              acc.regionName = part.long_name
              break

            case 'postal_code':
              acc.zipcode = part.short_name
              break

            case 'country':
              acc.country = part.short_name
              break
          }
        })

        return acc
      },
      {
        city: '',
        country: '',
        region: '',
        regionName: '',
        zipcode: '',
        latitude: '',
        longitude: '',
      }
    )

    // validate if the address is within an area that we service
    // if not we throw an INVALID_ADDRESS error
    const invalidRegions = ['HI', 'AK']
    const invalidRegionNames = ['Hawaii', 'Alaska']
    const validCountries = ['US', 'USA', 'United States']
    if (
      !tempGeoData.city ||
      !tempGeoData.zipcode ||
      !validCountries.includes(tempGeoData.country) ||
      invalidRegions.includes(tempGeoData.region) ||
      invalidRegionNames.includes(tempGeoData.regionName)
    ) {
      this.log.error('GEO_RESPONSE_INVALID', {
        request: geoRequest,
        response: results,
      })
      throw new Error('INVALID_ADDRESS')
    }

    const formatted = `${tempGeoData.city}, ${tempGeoData.region} ${tempGeoData.zipcode}, USA`

    if (formatted !== address?.formatted_address) {
      this.log.error('GEO_RESPONSE_WRONG_FORMAT', {
        ourFormat: formatted,
        googleFormat: address?.formatted_address,
      })
    }

    tempGeoData.latitude = address.geometry.location.lat()
    tempGeoData.longitude = address.geometry.location.lng()

    // if the address is valid then we set the location
    this._setLocation(tempGeoData)
  }

  private _setLocation(location: any) {
    const { city, country, region, regionName, zipcode, latitude, longitude } = location
    // Allow the save functionality to be overridden
    if (this.saveCallback) {
      this.saveCallback({
        city,
        country,
        region,
        regionName,
        zipcode,
        latitude,
        longitude,
      })
      this.saveCallback = null
      return
    }

    // Default Save Behavior
    this.state.value.city = city || ''
    this.state.value.country = country || ''
    this.state.value.region = region || ''
    this.state.value.regionName = regionName || ''
    this.state.value.zipcode = zipcode || ''
    this.state.value.latitude = latitude || ''
    this.state.value.longitude = longitude || ''
  }
}

declare global {
  interface GeoProviderState {
    city: string
    country: string
    region: string
    regionName: string
    zipcode: string
    latitude: string
    longitude: string
    locationOverride: GeoLocationOverride | null
    edgePostalCode: string
    browserPostalCode: string
    userPostalCode: string
    setFromCookie: boolean
  }
}

interface GeoLocationOverride {
  city: string | null
  region: string | null
  zipcode: string | null
}
