<template lang="pug">
HeaderPromoBar(v-if='$sitewideConfig')
HeaderLinkBar(v-if='$sitewideConfig')
Header(v-if='$sitewideConfig')
div(class='p-3 pb-4')
  div(v-if='error?.statusCode === 404' class='flex justify-evenly items-center my-6 lg:container')
    div(class='text-center text-gray' style='max-width: 650px')
      div(class='font-bold text-3xl') 404. Page Not Found.
      p(class='text-2xl') We're sorry, it looks like the page you're looking for has come to a dead end.
      h5 Let us help you find something else or head #[nuxt-link(:to='{ name: "home" }') back home!]
    div(class='hidden md:block')
      img(v-if='errorImage' :src='errorImage')
  div(v-else class='lg:container')
    h1 {{ h1 }}
    p(class='mb-4') Please refresh this page and try again.
    p {{ text }}
Footer(v-if='$sitewideConfig')
</template>
<script setup lang="ts">
import type { NuxtError } from '#app'
const { $storyblok, $sitewideConfig } = useNuxtApp()
const { error } = defineProps({
  // eslint-disable-next-line vue/require-default-prop
  error: Object as () => NuxtError,
})

const h1 = computed(() => {
  return error?.statusCode === 404 ? 'Not Found' : 'Internal Error'
})

const text = computed(() => {
  return error?.statusCode === 404 ? 'The page you requested does not exist' : error?.message
})

const errorImage = computed(() => {
  return $storyblok.formatSrc($sitewideConfig.config?.errorPageImage?.filename)
})

useMetaTags({
  title: h1.value,
  isNoIndex: true,
})
</script>
